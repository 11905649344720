
import React, { useState } from 'react';
import { Typography, Button, CircularProgress, Box, TextField, Card, CardContent, Grid, IconButton, Icon } from '@mui/material';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ToastContainer, toast } from 'react-toastify';
import MDBox from "../../components/MDBox"
import { FaRegChartBar } from "react-icons/fa";
import { TiDocumentText } from "react-icons/ti";
import { IoDocumentText } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from "layouts/authentication/components/Footer";

const OrderFetch = () => {
  const [loading, setLoading] = useState(false);
  const [updateloading, setUpdateloading] = useState(false);
  const [addedOrdersCount, setAddedOrdersCount] = useState(0);
  const [addedSingleOrdersCount, setAddedSingleOrdersCount] = useState(0);
  const [updatedSingleOrdersCount, setUpdatedSingleOrdersCount] = useState(0);
  const [failedSingleOrdersCount, setFailedSingleOrdersCount] = useState(0);
  const [updatedOrdersCount, setUpdatedOrdersCount] = useState(0);
  const [failedOrdersCount, setFailedOrdersCount] = useState(0);
  const [updatedDateOrdersCount, setUpdatedDateOrdersCount] = useState(0);
  const [failedDateOrdersCount, setFailedDateOrdersCount] = useState(0);
  const [orderName, setOrderName] = useState('');
  const [loadingSingleOrder, setLoadingSingleOrder] = useState(false);
  const [orderMonth, setOrderMonth] = useState('');
  const [loadingMonthOrder, setLoadingMonthOrder] = useState(false);
  const [addedOrderCount, setAddedOrderCount] = useState(0);
  const [updatedOrderCount, setUpdatedOrderCount] = useState(0);

  const [orderStartDate, setOrderStartDate] = useState('');
  const [orderEndDate, setOrderEndDate] = useState('');
  const [loadingDateOrder, setLoadingDateOrder] = useState(false);
  const [addedDateOrdersCount, setAddedDateOrdersCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFetchOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get('/SyncOrders', {
        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
      });
      const { orderAdded, orderUpdated, orderfailed } = response.data;

      setAddedOrdersCount(orderAdded);
      setUpdatedOrdersCount(orderUpdated);
      setFailedOrdersCount(orderfailed);
      toast.success(`Orders fetched successfully: ${orderAdded} added, ${orderUpdated} updated, ${orderfailed} failed `);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Error fetching orders');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setOrderName(event.target.value);
  };

  const handleFetchSingleOrders = async () => {
    if (!orderName) {
      toast.error('Order number is required!');
      return;
    }

    setLoadingSingleOrder(true);
    try {
      const response = await api.post(`/SyncSingleOrder?name=${orderName}`, {}, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });
      const { orderAdded, orderUpdated, orderfailed } = response.data;

      setAddedSingleOrdersCount(orderAdded);
      setUpdatedSingleOrdersCount(orderUpdated);
      setFailedSingleOrdersCount(orderfailed);
      toast.success(`Single order fetched successfully: ${orderAdded} added, ${orderUpdated} updated, ${orderfailed} failed`);

      // Uncomment if needed
      // setUpdatedSingleOrdersCount(orderUpdated);
    } catch (error) {
      console.error('Error fetching single order:', error);
      toast.error('Error fetching single order');
    } finally {
      setLoadingSingleOrder(false);
    }
  };

  const handleFetchOrder = async () => {
    setUpdateloading(true);
    try {
      const response = await api.post('/SyncOrder', {}, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
      });
      const { orderAdded, orderUpdated, orderFailed } = response.data;

      setUpdatedOrderCount(orderUpdated);
      toast.success(`Orders fetched successfully: ${orderUpdated} updated`);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Error fetching orders');
    } finally {
      setUpdateloading(false);
    }
  };
  const handleInputStartDateChange = (event) => {
    setOrderStartDate(event.target.value);
  };

  const handleInputEndDateChange = (event) => {
    setOrderEndDate(event.target.value);
  };

  // const handleFetchDateOrders = async () => {
  //   if (!orderStartDate || !orderEndDate) {
  //     toast.error('Both start and end dates are required!');
  //     return;
  //   }

  //   setLoadingDateOrder(true);
  //   try {
  //     const response = await api.post(`/SyncOrdersbyDates?start=${orderStartDate}&end=${orderEndDate}`, {}, {
  //       headers: {
  //         "Authorization": `Bearer ${localStorage.getItem("token")}`
  //       }
  //     });
  //     const { orderAdded, orderUpdated, orderfailed } = response.data;

  //     setAddedDateOrdersCount(orderAdded);
  //     setUpdatedDateOrdersCount(orderUpdated);
  //     setFailedDateOrdersCount(orderfailed);
  //     toast.success(`Orders for the date range fetched successfully: ${orderAdded} added , ${orderUpdated} updated, ${orderfailed} failed`);
  //     // Uncomment if needed
  //     // setUpdatedSingleOrdersCount(orderUpdated);
  //   } catch (error) {
  //     console.error('Error fetching date orders:', error);
  //     toast.error('Error fetching date orders');
  //   } finally {
  //     setLoadingDateOrder(false);
  //   }
  // };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer
        style={{
          position: isMobile ? 'top-right' : 'top-right',
          width: isMobile ? '80%' : '25%',
          fontSize: isMobile ? '12px' : '15px'
        }}
      />

      <Box p={2} >
        {/* <MDBox
          mb={2}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <Typography variant="h5" style={{ color: 'white' }} component="h2">
            Order Fetch
          </Typography>
        </MDBox> */}

        <Grid container spacing={2} alignItems="center" >
          <Grid item xs={12} >

            <Card variant="outlined" >
              <Typography align='start' style={{ color: 'black', margin: '20px', fontSize: '30px', fontWeight: '500' }}>
                All Fetch Order Status
              </Typography>
              <CardContent sx={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', height: '100%' }}>
                <Grid container spacing={4} alignItems="center">

                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined" sx={{
                      minHeight: '130px', backgroundColor: '#939798', boxShadow: ' 3px 7px 8px 4px #817d7d', transition: '0.3s',
                      '&:hover': {
                        backgroundColor: '#a8a8a8', // Change to desired hover color
                        boxShadow: '3px 7px 12px 6px #6e6e6e',
                      },
                    }}>
                      <Typography align="start" variant="body1" style={{ textShadow: '0.5px 0.5px 1px #8a9399', color: '#4c4c4d', margin: '10px', fontSize: '20px', fontWeight: '500' }} component="div" >
                        Order Fetch
                      </Typography>
                      <Icon style={{ boxShadow: ' 3px 7px 8px 4px #817d7d', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', backgroundColor: '#4c4c4d', color: 'black', margin: '15px' }} >
                        <IoDocumentText style={{ color: 'white', alignItems: 'center', height: '30px', width: 'auto' }} />
                      </Icon>
                      <CardContent>
                        <Typography variant="h5" style={{ color: 'white', display: 'flex', justifyContent: 'start', margin: '15px 15px 15px 0px' }} component="h1">
                          Orders Added: {addedOrdersCount}
                        </Typography>
                        <Typography variant="h5" style={{ color: 'white', display: 'flex', justifyContent: 'start', margin: '15px 15px 15px 0px' }} component="h1">
                          Orders Updated: {updatedOrdersCount}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="start">
                          <Button
                            variant="contained"
                            onClick={handleFetchOrders}
                            disabled={loading}
                            style={{ background: '#42424a', color: '#fff' }}
                          >
                            {loading ? 'Fetching...' : 'Fetch Orders'}
                          </Button>
                        </Box>
                        {/* Lock screen overlay */}
                        {loading && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: 'maxheight',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
                              zIndex: 2, // Ensure it overlays on top of all content
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress style={{ color: 'white' }} />
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined" sx={{
                      minHeight: '120px', backgroundColor: '#e1d8d1', boxShadow: ' 3px 7px 8px 4px #817d7d', transition: '0.3s',
                      '&:hover': {
                        backgroundColor: '#d3cfcf', // Light hover color
                        boxShadow: '3px 7px 12px 6px #6e6e6e',

                      },
                    }}>
                      <Typography align="start" variant="body1" style={{ textShadow: '0.5px 0.5px 1px #8a9399', color: '#9f8383', margin: '10px', fontSize: '20px', fontWeight: '500' }} component="div" >
                        Order Fetch by Order Number
                      </Typography>
                      <Icon style={{ boxShadow: ' 3px 7px 8px 4px #817d7d', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', backgroundColor: '#9f8383', color: 'black', margin: '15px 15px 0px 15px' }} >
                        <TiDocumentText style={{ color: 'white', alignItems: 'center', height: '30px', width: 'auto' }} />
                      </Icon>
                      <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextField
                            type="text"
                            label="Fetch Single Order"
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            style={{
                              top: isMobile ? '10px' : '-50px',
                            }}
                            sx={{
                              width: 'fit-content',
                              borderRadius: '20px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#000',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#000',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#000',
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: { color: 'black' },
                            }}
                          />
                        </div>
                        <Typography variant="h5" style={{ color: '#000000b5', display: 'flex', justifyContent: 'start', margin:isMobile ? '15px 15px 15px 0px' : '0px 15px 15px 0px' }} component="h1">
                          Orders Added: {addedSingleOrdersCount}
                        </Typography>
                        <Typography variant="h5" style={{ color: '#000000b5', display: 'flex', justifyContent: 'start', margin: '15px 15px 15px 0px' }} component="h1">
                          Orders Updated: {updatedSingleOrdersCount}
                        </Typography>



                        {/* <Typography  variant="h6" style={{ display: 'flex',flexDirection:'row' ,justifyContent: 'space-between' }} component="h2" gutterBottom mt={2}>
                           <Typography component="h2" variant="h6">
                           Orders Added:{addedSingleOrdersCount}
                           </Typography>
                           <Typography component="h2" variant="h6">
                           Orders Updated:{updatedSingleOrdersCount}
                           </Typography>
                        </Typography> */}
                        <Box mt={2} display="flex" justifyContent="start">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFetchSingleOrders}
                            disabled={loadingSingleOrder}
                            style={{ background: '#42424a', color: '#fff' }}
                          >
                            {loadingSingleOrder ? 'Fetching...' : 'Fetch Order by Order Number'}
                          </Button>
                        </Box>
                        {/* Lock screen overlay */}
                        {loadingSingleOrder && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: 'maxheight',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
                              zIndex: 2, // Ensure it overlays on top of all content
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress style={{ color: 'white' }} />
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined" sx={{
                      minHeight: '130px', backgroundColor: '#939798', boxShadow: ' 3px 7px 8px 4px #817d7d', transition: '0.3s',
                      '&:hover': {
                        backgroundColor: '#a8a8a8', // Change to desired hover color
                        boxShadow: '3px 7px 12px 6px #6e6e6e',
                      // minHeight: '150px', backgroundColor: '#d3dce1', boxShadow: ' 3px 7px 8px 4px #9da9aff2',
                      // transition: '0.3s',
                      // '&:hover': {
                      //   backgroundColor: '#c0c8d4',
                      //   boxShadow: '3px 7px 12px 6px #8a9baf',
                      },
                    }}>
                      <Typography align="start" variant="body1" style={{ textShadow: '0.5px 0.5px 1px #8a9399', color: '#4c4c4d', margin: '10px', fontSize: '20px', fontWeight: '500' }} component="div" >
                        Update Orders
                      </Typography>
                      <Icon style={{ boxShadow: ' 3px 7px 8px 4px #817d7d', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', backgroundColor: '#4c4c4d', color: 'black', margin: '15px' }} >
                        <IoDocumentText style={{ color: 'white', alignItems: 'center', height: '30px', width: 'auto' }} />
                      </Icon>
                      <CardContent>
                        <Typography hidden={true} variant="h5" style={{ color: '#6e6e6e', display: 'flex', justifyContent: 'start', margin: '15px 15px 15px 0px' }} component="h1">
                          .
                        </Typography>
                        <Typography variant="h5" style={{ color: 'white', display: 'flex', justifyContent: 'start', margin: '15px 15px 15px 0px' }} component="h1">
                          Orders Updated: {updatedOrderCount}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="start">
                          <Button
                            variant="contained"
                            onClick={handleFetchOrder}
                            disabled={updateloading}
                            style={{ background: '#42424a', color: '#fff' }}
                          >
                            {updateloading ? 'Fetching...' : 'Fetch Orders'}
                          </Button>
                        </Box>
                        {/* Lock screen overlay */}
                        {updateloading && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: 'maxheight',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
                              zIndex: 2, // Ensure it overlays on top of all content
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress style={{ color: 'white' }} />
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <Card variant="outlined" sx={{
                      minHeight: '150px', backgroundColor: '#d3dce1', boxShadow: ' 3px 7px 8px 4px #9da9aff2',
                      transition: '0.3s',
                      '&:hover': {
                        backgroundColor: '#c0c8d4',
                        boxShadow: '3px 7px 12px 6px #8a9baf',
                         minHeight: '120px', backgroundColor: '#e1d8d1', boxShadow: ' 3px 7px 8px 4px #817d7d', transition: '0.3s',
                      '&:hover': {
                        backgroundColor: '#d3cfcf', // Light hover color
                        boxShadow: '3px 7px 12px 6px #6e6e6e',
                      },
                    }}>
                      <Typography align="start" variant="body1" style={{ textShadow: '0.5px 0.5px 1px #8a9399', color: '#8a9399', margin: '10px', fontSize: '20px', fontWeight: '500' }} component="div" >
                        Order Fetch by Date
                      </Typography>
                      <Icon style={{ boxShadow: ' 3px 7px 8px 4px #9da9aff2', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', backgroundColor: '#8a9399', color: 'black', margin: '15px' }} >
                        <HiOutlineDocumentText style={{ color: 'white', alignItems: 'center', height: '30px', width: 'auto' }} />
                      </Icon>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between">
                          <TextField
                            type="date"
                            label="Start Date"
                            required
                            onChange={handleInputStartDateChange}
                            variant="outlined"
                            sx={{
                              width: 'fit-content',
                              borderRadius: '20px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#000',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#000',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#000',
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: { color: 'black' },
                              shrink: true,
                            }}
                          />
                          <TextField
                            type="date"
                            label="End Date"
                            onChange={handleInputEndDateChange}
                            variant="outlined"
                            required
                            sx={{
                              marginLeft: '5px',
                              width: 'fit-content',
                              borderRadius: '20px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#000',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#000',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#000',
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: { color: 'black' },
                              shrink: true,
                            }}
                          />
                        </Box>
                        <Typography  variant="h6" style={{ display: 'flex',flexDirection:'row' ,justifyContent: 'space-between' }} component="h2" gutterBottom mt={2}>
                           <Typography component="h3" variant="h6">
                           Orders Added:{addedDateOrdersCount}
                           </Typography>
                           <Typography component="h3" variant="h6">
                           Orders Updated:{updatedDateOrdersCount}
                           </Typography>
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="start">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFetchDateOrders}
                            disabled={loadingDateOrder}
                            style={{ background: '#42424a', color: '#fff' }}
                          >
                            {loadingDateOrder ? 'Fetching...' : 'Fetch Order by Date'}
                          </Button>
                        </Box>
                       
                        {loadingDateOrder && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: 'maxheight',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
                              zIndex: 2, // Ensure it overlays on top of all content
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress style={{ color: 'white' }} />
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid> */}

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>


      </Box>


      {/* <Footer dark /> */}
    </DashboardLayout>

  );
};

export default OrderFetch;


