import axios from "axios";
import { redirect } from "react-router-dom";

// Create an instance of axios with the base URL of your Swagger API
const api = axios.create({
  //baseURL: "http://192.168.29.192/indiashopping/api",
   // baseURL: "https://localhost:44311/api", // Update with the base URL of your Swagger API
  baseURL: "https://backofficeapi.rezili.com/api",
  //baseURL:"http://13.126.39.62:8080/api",
});
 
// Interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.error("Request failed with status:", error.response.status);
      console.error("Error response data:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received from server:", error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
